@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@import url("normalize.css");

:root {
  --WS-purple: #581b51;
  --WS-dark-blue: #07294c;
  --WS-light-blue: #2699bb;
  --WS-grey-blue: #314f6c;
  --WS-green: #81945c;
  --WS-yellow: #ffcc00;
  --WS-orange: #f36b24;
  --error: #dc3545;
  --interactable-height: 44px;
  --padding-v: 12px;
  --padding-h: 6px;
  --font-weight-bold: 700;
}

body {
  font-family: "Open Sans", sans-serif;
  background-color: var(--WS-dark-blue);
  color: white;
  font-weight: 400;
}

body.style-plain {
  --WS-purple: purple;
  --WS-dark-blue: darkblue;
  --WS-light-blue: #2699bb;
  --WS-grey-blue: darkblue;
  --WS-green: green;
  --WS-yellow: yellow;
  --WS-orange: orange;

  font-family: sans-serif;
  background-color: white;
  color: black;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--font-weight-bold);
}

h4 {
  margin-block-end: 1em;
}

.app {
  max-width: 500px;
  margin: 0 auto;
  padding: 12px;
}

form {
  border-radius: 4px;
}

.form-error {
  background-color: var(--error);
  color: white;
  border-radius: 2px;
  padding: 6px 8px;
}

.field-error {
  margin-top: -12px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label,
.form-group input,
.form-group select {
  display: block;
  margin-bottom: 0.5rem;
}
.form-group label {
  font-weight: var(--font-weight-bold);
}

.form-group input,
.form-group select {
  border: 2px solid transparent;
}

.form-group input,
.form-group select,
form button {
  width: 100%;
}

/* Hides scroll bar on select dropdown */
select::-webkit-scrollbar {
  width: 0 !important;
}
select {
  appearance: none;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath d='M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z' fill='%23FFFFFF'/%3E%3C/svg%3E")
    no-repeat 100% 50%;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  background-size: 25px 50%;
}

button {
  background-color: var(--WS-yellow);
  height: var(--interactable-height);
  padding: var(--padding-v) 50px var(--padding-v) var(--padding-h);
  border-radius: 4px;
  color: var(--WS-dark-blue);
  border: 0;
  font-weight: var(--font-weight-bold);
  font-size: 1.2rem;
  white-space: normal;
  position: relative;
  cursor: pointer;
}

body.style-plain button {
  background-color: var(--WS-light-blue);
  color: white;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

button svg {
  position: absolute;
  right: 0;
  top: 0;
  height: var(--interactable-height) !important;
  background-color: var(--WS-orange);
  padding: 9px 8px 10px;
  border-radius: 0 4px 4px 0;
  box-sizing: border-box !important;
}
button svg path {
  fill: var(--WS-dark-blue);
}

button.in-progress svg path {
  position: relative;
  transform-origin: 50% 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

body.style-plain button svg {
  background-color: transparent;
}
body.style-plain button svg path {
  fill: white;
}

.form-group.error input,
.form-group.error select {
  border-color: var(--error);
}

select,
input {
  color: white;
  border: 0;
  border-radius: 5px;
  height: var(--interactable-height);
  padding: var(--padding-v) var(--padding-h);
  background-color: var(--WS-grey-blue);
}

body.style-plain select,
body.style-plain input {
  color: var(--WS-dark-blue);
  background-color: white;
  border: 1px solid #ccc;
}

.disclaimer {
  font-size: 0.85em;
}

.button-group {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.button-group > button {
  flex: 1;
}

button.incorrect {
  background-color: var(--WS-grey-blue);
  color: white;
}

button.in-progress svg path {
  animation: spin 1s linear infinite;
  transform-origin: center;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

address {
  border: 1px solid white;
  background-color: var(--WS-grey-blue);
  margin-bottom: 1.5rem;
  padding: 1rem;
  font-style: normal;
}

body.style-plain address {
  border-color: #ccc;
  background-color: transparent;
}

.grecaptcha-badge {
  visibility: hidden;
}

.product-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 1em;
}

.product-button {
  height: auto;
}
.product-button div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.product-button svg {
  top: 50%;
  background-color: transparent;
  transform: translateY(-50%);
}

.product-button.selected {
  background-color: var(--WS-orange);
}

.checkbox-container {
  position: relative;
  margin-bottom: 3em;
}

.checkbox-container:not(:last-of-type) {
  margin-bottom: 1em;
}

.checkbox-container input {
  appearance: none;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  background-color: var(--WS-grey-blue);
  transition: 0.2s ease-in-out;
  width: var(--interactable-height);
  height: var(--interactable-height);
  position: relative;
  float: left;
  margin-right: 10px;
}

.checkbox-container input:checked {
  background-color: var(--WS-orange);
}

.checkbox-container svg {
  pointer-events: none;
  position: absolute;
  top: 22px;
  left: 12px;
  transform: translate(0, -50%);
  fill: var(--WS-grey-blue);
}

.checkbox-container label {
  cursor: pointer;
}

.step-final {
  text-align: center;
  margin-top: 3em;
}
